<template>
  <div class="item_root" v-if="val && val.length>0">
    <div class="super_root_item" @click="func(val[0].products[0].id)">
      <div class="store_product_image_cont">
        <img :src="val[0].products[0].productImages[0]" alt="No image found" class="store_product_image" />
      </div>
      <div style="margin-left: 0.5rem; margin-top: 0.3rem;" class="bold_ break_word item_name">{{ val[0].products[0].name }}
      </div>
      <div style="font-size: 1rem; margin-left: 0.5rem;" class="break_word">{{ val[0].products[0].description }}</div>
      <div v-if="val" style="font-size: 1rem; margin-left: 0.5rem;">{{ val[0].title }}: <span
          class="bold_">{{ val[0].name }}</span></div>
      <div v-if="val && val[0]" style="margin-left: 0.5rem;" class="blue_color bold_  price_text">₹
        {{ val[0].priceOffer ? val[0].priceOffer : val[0].priceOriginal }}</div>
    </div>
    <div style="min-width: 4rem; height: 3.8rem;" @click="addOrRemoveFromCart">
      <b-button v-if="val[0].inventoryQuantity<=0" variant="none" class="add_to_cart_btn" :disabled="true">Out Of Stock</b-button>
      <b-button v-else variant="none" class="add_to_cart_btn">Add to cart</b-button>
    </div>
  </div>
</template>
<script>
import { BModal, VBModal, BAlert, BCardText, BOverlay } from "bootstrap-vue";
import BCardCode from "@core/components/b-card-code";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapMutations, mapActions, mapState } from "vuex";
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
} from "bootstrap-vue";

export default {
  name: 'Item',
  components: {
    BCardCode,
    BButton,
    BModal,
    BAlert,
    BRow,
    BImg,
    BCol,
    BLink,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  props: ["val"],
  data() {
    return {
      show: false,
      isItemInCart: false
    };
  },
  computed: mapState({
    products: state => {
      return state.storeProducts.products
    },
    tempVariant(state) {
      return state.storeProducts.tempVariant;
    },
    currUser(state) {
      return state.login.currUser;
    },
  }),
  async mounted() {
    // await this.findItemInCart()
  },
  methods: {
    func(val) {
      this.onClick(val);
    },
    onClick(_id) {
      this.$router.push(`/store/product/${_id}`);
    },
    ...mapActions({
      getProductVariant: "storeProducts/getProductVariant",
      addItemToCart: "storeShoppingCartItems/addItemToCart",
      setShoppingCartItems: "storeShoppingCartItems/setShoppingCartItems",
      getItemInCart: "storeShoppingCartItems/getItemInCart",
      deleteFromCart: "storeShoppingCartItems/deleteFromCart"
    }),
    shortName(name) {
      let str = name;
      if (str.length > 40) return str.slice(0, 40) + '...'
      return name
    },
    async addOrRemoveFromCart() {
      // const res = await this.findItemInCart()
      // if (this.isItemInCart) {
      //   await this.deleteFromCart(res.data[0].id)
      //   const userId = this.currUser ? this.currUser.id : null;
      //   // await this.setShoppingCartItems({ userId });
      //   this.isItemInCart = false;
      //   this.$swal({
      //     icon: 'success',
      //     title: 'Item removed from cart successfully',
      //   }).then(function () {
      //   });
      // }
      // else {
        const productId = this.val[0].products[0].id;
        const quan = 1;
        const variantId = this.val[0].id;
        const anonymousUserId = localStorage.getItem("anonymousUserId");
        const userId = this.currUser ? this.currUser.id : null;
        await this.addItemToCart({ quan, productId, variantId, anonymousUserId, userId });
        await this.setShoppingCartItems({ userId });
        this.isItemInCart = true
        this.$swal({
          icon: 'success',
          title: 'Item added to cart successfully',
        }).then(function () {
        });
      // }
    },
    async findItemInCart() {
      let anonymousUserId = null;
      if(!localStorage.getItem("anonymousUserId")){
        anonymousUserId = null
      }
      else{
        anonymousUserId = localStorage.getItem("anonymousUserId");
      }
      const userId = this.currUser ? this.currUser.id : null;
      const res = await this.getItemInCart({ productId: this.val[0].products[0].id, variantId: this.val[0].id, anonymousUserId: anonymousUserId, userId: userId })
      if (res.data.length > 0)
        this.isItemInCart = true;
      return res
    }
  },

  watch: {
    async tempVariant(nv, ol) {
    },
  },
};
</script>
<style scoped>
.item_root {
  display: flex;
  flex-direction: column;
  height: 30rem;
  width: 22rem;
}

.super_root_item {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  font-weight: normal;
  cursor: pointer;
  border-radius: 0.5rem;
  gap: 0.2rem;
  overflow-y: hidden;
}

.item_root:hover {
  border-radius: 0.5rem;
  background: #FFF;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.10);
  transition: 0.3s;
}

.store_product_image_cont {
  width: 100%;
  height: 16rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem 0.5rem 0 0;
}

.store_product_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.5rem 0.5rem 0 0;
}

.blue_color {
  color: #000080;
}

.add_to_cart_btn {
  margin-top: 2.2rem;
  color: #FF6F00;
  padding: 0.7rem;
  background: rgba(255, 111, 0, 0.10);
  width: 100%;
  height: 100%;
  font-size: 1.3rem;
  font-weight: 600;
  border-radius: 0 0 0.5rem 0.5rem;
}

.add_to_cart_btn:hover {
  background: #FF6F00;
  color: white;
}

.bold_ {
  font-weight: 600;
}

.break_word {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.item_name {
  font-size: 1.5rem; 
}
.price_text {
  font-size: 1.5rem; 
}
/*========================= Media Queries (Small devices) =========================*/
@media screen and (max-width: 800px) {
  .item_name {
    font-size: 1rem;
  }
  .price_text {
    font-size: 1rem; 
  }
  .item_root {
    display: flex;
    flex-direction: column;
    height: 22rem;
    width: 10.5rem;
  }
  .store_product_image_cont {
    height: 10rem;
  }
}

@media screen and (max-width: 400px) {}
</style>