<template>
  <div>
    <Carousel
      :perPageCustom="[
        [200, 1],
        [768, 1],
      ]"
      :paginationEnabled="false"
      v-bind:autoplay="true" v-bind:loop="true" v-bind:autoplayTimeout="3000" v-bind:autoplayHoverPause="false"
      class="category_banner_image_container" 
      v-if="productCategories && category.name!=productCategories[0].name"
      >
        <slide v-for="(obj, indx) in category.banner" :key="indx" class="image_x">
          <Banner :val="obj" :category="category" :style="{'background-image': `url(${obj.imageUrl})`}" class="category_banner_image" :showBtn="true"/>
        </slide>
    </Carousel>
    <div style="margin-top: 2rem; display: flex; flex-direction: row; justify-content: space-between; margin-bottom: 1rem;" class="cat_heading__">
      <div class="cat_title">{{category.title}}</div>
      <div style="font-size: 1rem; cursor: pointer; margin-right: 2rem;" class="blue_view_all" @click="viewItemsAccordingToCategory(category)">View All <i class="fa-solid fa-chevron-right"></i></div>
    </div>
      <div class="df scroll-container" ref="scrollContainer" @scroll="handleScroll">
        <div v-if="category && variants.has(category.name)" class="products__ scroll-content">
          <div v-for="item in productIds[category.name]" >
            <Item :val="variants.get(category.name)[item]"/>
          </div>
        </div>
      </div>
  </div>
</template>
<script>
import { BModal, VBModal, BAlert, BPaginationNav, BPagination, BCardText } from "bootstrap-vue";
import BCardCode from "@core/components/b-card-code";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapMutations, mapActions, mapState } from "vuex";
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
} from "bootstrap-vue";
import Categories from "./Categories.vue";
import Item from "./Item.vue";
import { Carousel, Slide } from "vue-carousel";
import Banner from "./Banner.vue";

export default {
  name: 'Items',
  components: {
    Item,
    BPaginationNav,
    BPagination,
    BCardCode,
    BButton,
    BModal,
    BAlert,
    BRow,
    BImg,
    BCol,
    BLink,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    Categories,
    Carousel,
    Slide,
    Banner
  },
  directives: {
    Ripple,
  },
  props: ['category'],
  data() {
    return {
      modifiedProducts: [],
      finalProducts: [],
      itemsPerPage: 5,
      currentPageInternal: null,
      isLoading: false,
      displayedItems: {}
    };
  },
  methods: {
    ...mapActions({
      fetchProducts: "storeProducts/fetchProducts",
      setCurrentPage: "storeProducts/setCurrentPage",
      getVariants: "storeProducts/getTempVariant",
      setSelectedCategoryProducts: "storeProducts/setSelectedCategoryProducts"
    }),
    setIsLoading(val) {
      this.isLoading = val;
    },
    async setFinalProducts(newcurrentPage = -1, nw = null) {
      // check if products are there..
      if (!this.products) return;
    },
    async viewItemsAccordingToCategory(selectedCategory){
      await this.setSelectedCategoryProducts({selectedCategory: selectedCategory})
      localStorage.setItem("category", JSON.stringify(selectedCategory))
      this.$router.push({path:`/store/category/${selectedCategory.name}`, query: {category: selectedCategory}})
    },
    async loadMoreItems() {
      if(this.variants.has(this.category.name)){
        const startIndex = Object.keys(this.variants.get(this.category.name)).length;
        const endIndex = startIndex + this.itemsPerPage;
        await this.getVariants({selectedCategory: this.category, limit: endIndex});
      }
    },
    async handleScroll() {
      const container = this.$refs.scrollContainer;
      if ((container.scrollLeft + container.clientWidth) <= container.scrollWidth) {
        await this.loadMoreItems();
      }
    },
  },
  computed: mapState({
    products(state) {
      return state.storeProducts.products;
    },
    selectedCategory(state) {
      return state.storeProducts.selectedCategory;
    },
    selectedSubCategory(state) {
      return state.storeProducts.selectedSubCategory;
    },
    totalItems(state) {
      return this.variants.has(this.category.name) ? this.variants.get(this.category.name).length : 0;
    },
    currentPage(state) {
      this.currentPageInternal = state.storeProducts.currentPage;
      return state.storeProducts.currentPage;
    },
    productCategories: state => {
      return state.storeProducts.productCategories
    },
    variants: state => {
      return state.storeProducts.tempVariant
    },
    productIds: state => {
      return state.storeProducts.productIdsForAllCategories
    }
  }),
  watch: {
    async products(nw, prev) {
      this.setIsLoading(true);
      this.setFinalProducts();
      this.setIsLoading(false);
    },
  },
  async mounted() {
    this.setIsLoading(true);
    await this.getVariants({selectedCategory: this.category, limit: 5});
    this.loadMoreItems();
  },
};
</script>
<style scoped>
.root_items {
  padding: 0 8rem;
  padding-top: 2rem;
  padding-right: 3rem;
  margin-bottom: 3rem;
}

.df {
  display: flex;
  flex-direction: row;
  gap: 3rem;
  overflow-x: scroll;
  width: 100vw;
  white-space: nowrap;
}
.scroll-content{
  white-space: nowrap;
}

.__pagination {
  margin-top: 1.4rem;
}

.products__{
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 2rem;
  gap: 3rem;
}
div::-webkit-scrollbar {
  display: none;
}
.blue_view_all{
  color: #4385F5;
}
.category_banner_image_container{
  width: 100%;
  height: 30rem;
  margin-bottom: 2rem;
  object-fit: contain;
  margin-left: 0;
  background-color: #4385F5;
}
.category_banner_image{
  width: 100%;
  height: 30rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
  display: flex;
  justify-content: center;
}
.image_x{
  width: 100%;
  height: 30rem;
  background-image: url('../../../assets/yicimages/store/jewellery.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
  display: flex;
  align-items: center;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.backdrop {
  /* position: fixed; */
  /* background: rgba(0, 0, 0, 0.5); */
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.3;
}


/*========================= Media Queries (Small devices) =========================*/
@media screen and (max-width: 800px) {
  .root_items {
    padding: 0.5rem;
    margin-bottom: 3rem;
    overflow: hidden;

  }
  .cat_title{
    font-size: 1rem;
  }
  .cat_heading__{
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .products__{
    width: fit-content;
    display: flex;
    flex-direction: row;
    margin-bottom: 2rem;
    gap: 1rem;
  }
  .df {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    overflow-x: scroll;
    width: auto;
  }
  .category_banner_image_container{
    height: 20rem;
  }
  .image_x {
    height: 20rem;
  }
  .category_banner_image{
    height: 20rem;
  }

}

@media screen and (max-width: 400px) {
}

@media screen and (max-width: 330px) {

}
</style>